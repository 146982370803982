import React, { useState } from 'react';
import { Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import './footer.scss';
import securedImg from '../../assets/images/png/secured.png';
import LogoWhiteImg from '../logo/LogoWhite';
import Logout from '../logout/Logout';
import SupportEmail from './SupportEmail';

const Footer = () => {
  //open popup modal component functions
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const openChat = () => {
    return window.LC_API.open_chat_window();
  };

  return (
    <footer className="footer">
      <Row>
        <div className="col-12 col-md-12 col-lg-6 footer-left-content">
          <div className="mb-3">
            <LogoWhiteImg title="Logo" />
            <img
              src={securedImg}
              className="img-fluid logo ml-5 float-xs-right"
              alt=""
              title="CompliAssure Secured"
            />
          </div>
          <Row>
            <ul className="mt-4 mr-0 mr-lg-5 pl-3">
              <h5 className="mb-4 below-header-text">
                <b>Contact Us</b>
              </h5>
              <li>
                <span onClick={toggleModal} className="openPopup">
                  Email Support
                </span>
              </li>
              <li>
                <span onClick={openChat} style={{ cursor: 'pointer' }}>
                  Live Chat
                </span>
              </li>
              <li>
                <a href="tel:1-844-775-4635">Call: 844-775-4635</a>
              </li>
              <li>
                <a href='mailto:customerservice@line5.com'>Email: customerservice@line5.com</a>
              </li>
            </ul>
            <ul className="mt-4 mr-0 mr-lg-5 pl-3">
              <h5 className="mb-4 below-header-text">
                <b>Navigation</b>
              </h5>
              {localStorage.getItem('user_auth_data') ? (
                <React.Fragment>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Logout title="Logout"> Logout </Logout>
                  </li>
                </React.Fragment>
              ) : (
                <li>
                  <Link to="/">Login</Link>
                </li>
              )}
            </ul>
          </Row>
        </div>
        <div className="col-12 col-md-12 col-lg-6 text-center text-lg-right footer-right-content">
          <ul className="p-0 w-100">
            <li>
              <span>2019 Copyright. All rights reserved.</span>
            </li>
            <li>
              |&nbsp;&nbsp;
              <Link to="/Privacy">
                <u>Privacy</u>
              </Link>
            </li>
            <li>
              |&nbsp;&nbsp;
              <Link to="/TermsAndConditions">
                <u>Term & Conditions</u>
              </Link>
            </li>
          </ul>
        </div>
      </Row>
      <SupportEmail toggleModal={toggleModal} isModalOpen={showModal} />
    </footer>
  );
};

export default Footer;
